<template>
  <v-dialog
    :value="isShow"
    @click:outside="cancel"
    @input="$emit('update:isShow', $event)"
    :max-width="800"
    persistent
  >
    <v-card>
      <v-card-title v-if="isAlert" class="blue-grey lighten-3" primary-title>
        <v-icon color="#fdd35c" class="mr-1">mdi-alert</v-icon>
        {{ title }}
        <v-icon color="#fdd35c" class="ml-1">mdi-alert</v-icon>
      </v-card-title>
      <v-card-title v-else class="blue-grey lighten-3" primary-title>{{ title }}</v-card-title>
      <v-card-text v-if="changeFlag" class="pa-4">
        <p>
          <span>{{ redMessage }}</span
          >{{ message }}
        </p>
      </v-card-text>
      <v-card-text v-if="screenFlag" class="pa-4">
        <p>
          {{ message }}
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-checkbox
          v-if="checkBox"
          :value="check"
          :label="checkBoxTxt"
          @change="checkAction($event)"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn v-if="okBtnColor" :color="okBtnColor" @click="ok"> {{ okBtnTxt }} </v-btn>
        <v-btn v-else @click="ok"> {{ okBtnTxt }} </v-btn>
        <v-btn v-if="cancelBtnFlg" @click="cancelBtn"> {{ cancelBtnTxt }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "確認",
      required: false,
    },
    message: {
      type: String,
      required: true,
    },
    redMessage: {
      type: String,
      required: false,
    },
    okAction: {
      type: Function,
      required: true,
    },
    screenFlag: {
      type: Boolean,
      required: false,
    },
    changeFlag: {
      type: Boolean,
      required: false,
    },
    cancelAction: {
      type: Function,
      default: () => {},
      required: false,
    },
    okBtnTxt: {
      type: String,
      required: false,
      default: "OK",
    },
    cancelBtnTxt: {
      type: String,
      required: false,
      default: "キャンセル",
    },
    cancelBtnFlg: {
      type: Boolean,
      required: false,
      default: true,
    },
    okBtnColor: {
      type: String,
      required: false,
      default: void 0,
    },
    isAlert: {
      type: Boolean,
      required: false,
      default: false,
    },
    outsideClickNotCloseFlg: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkBox: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkBoxTxt: {
      type: String,
      required: false,
      default: "",
    },
    check: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    ok() {
      this.$emit("update:isShow", false);
      this.okAction();
    },
    cancel() {
      if (!this.outsideClickNotCloseFlg) {
        this.cancelAction();
        this.$emit("update:isShow", false);
      }
    },
    cancelBtn() {
      this.cancelAction();
      this.$emit("update:isShow", false);
    },
    checkAction(val) {
      this.$emit("check", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}
span {
  color: red;
}
</style>
