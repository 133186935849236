<template>
  <div style="background: white">
    <v-container id="sub-bar">
      <v-card-title class="indigo white--text text-h5"> メニュー </v-card-title>
      <!-- ホーム -->
      <v-row>
        <v-col v-if="getMenuRole('M_HOM')">
          <v-btn class="btn-home" @click="movePage('M_HOM')" text block>{{
            getText("M_HOM")
          }}</v-btn>
        </v-col>
      </v-row>
      <!-- 入荷準備-->
      <v-row v-if="getMenuRole('M_RCV_RDY')">
        <v-col>
          <div>
            <v-btn @click="open('M_RCV_RDY')" text block>{{ getText("M_RCV_RDY") }}</v-btn>
          </div>

          <div v-if="getMenuRole('P_RCV_SCHEDULE_ADD') && open_M_RCV_RDY">
            <v-btn @click="movePage('P_RCV_SCHEDULE_ADD')" text block
              >&emsp;{{ getText("P_RCV_SCHEDULE_ADD") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_RCV_SCHEDULE_DATA') && open_M_RCV_RDY">
            <v-btn @click="movePage('P_RCV_SCHEDULE_DATA')" text block
              >&emsp;{{ getText("P_RCV_SCHEDULE_DATA") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_RCV_DIFFERENCE_DATA') && open_M_RCV_RDY">
            <v-btn @click="movePage('P_RCV_DIFFERENCE_DATA')" text block
              >&emsp;{{ getText("P_RCV_DIFFERENCE_DATA") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_RCV_SCHEDULE_LIST') && open_M_RCV_RDY">
            <v-btn @click="movePage('P_RCV_SCHEDULE_LIST')" text block
              >&emsp;{{ getText("P_RCV_SCHEDULE_LIST") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 入荷-->
      <v-row v-if="getMenuRole('M_RCV')">
        <v-col>
          <div>
            <v-btn @click="open('M_RCV')" text block>{{ getText("M_RCV") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_RCV_ACTUAL_REPORT') && open_M_RCV">
            <v-btn @click="movePage('P_RCV_ACTUAL_REPORT')" text block
              >&emsp;{{ getText("P_RCV_ACTUAL_REPORT") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 入庫-->
      <v-row v-if="getMenuRole('M_ENT')">
        <v-col>
          <div>
            <v-btn @click="open('M_ENT')" text block>{{ getText("M_ENT") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_ENT_ACTUAL_SERCH') && open_M_ENT">
            <v-btn @click="movePage('P_ENT_ACTUAL_SERCH')" text block
              >&emsp;{{ getText("P_ENT_ACTUAL_SERCH") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 出荷準備 -->
      <v-row v-if="getMenuRole('M_SHP_RDY')">
        <v-col>
          <div>
            <v-btn @click="open('M_SHP_RDY')" text block>{{ getText("M_SHP_RDY") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_SHP_SCHEDULE_ADD') && open_M_SHP_RDY">
            <v-btn @click="movePage('P_SHP_SCHEDULE_ADD')" text block
              >&emsp;{{ getText("P_SHP_SCHEDULE_ADD") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SHP_SCHEDULE_DATA') && open_M_SHP_RDY">
            <v-btn @click="movePage('P_SHP_SCHEDULE_DATA')" text block
              >&emsp;{{ getText("P_SHP_SCHEDULE_DATA") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SHP_DIFFERENCE_DATA') && open_M_SHP_RDY">
            <v-btn @click="movePage('P_SHP_DIFFERENCE_DATA')" text block
              >&emsp;{{ getText("P_SHP_DIFFERENCE_DATA") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SHP_SCHEDULE_LIST') && open_M_SHP_RDY">
            <v-btn @click="movePage('P_SHP_SCHEDULE_LIST')" text block
              >&emsp;{{ getText("P_SHP_SCHEDULE_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SHP_SLIP') && open_M_SHP_RDY">
            <v-btn @click="movePage('P_SHP_SLIP')" text block
              >&emsp;{{ getText("P_SHP_SLIP") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SHP_TRUCK_NUM_ADD') && open_M_SHP_RDY">
            <v-btn @click="movePage('P_SHP_TRUCK_NUM_ADD')" text block
              >&emsp;{{ getText("P_SHP_TRUCK_NUM_ADD") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 出庫-->
      <v-row v-if="getMenuRole('M_EXT')">
        <v-col>
          <div>
            <v-btn @click="open('M_EXT')" text block>{{ getText("M_EXT") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_STK_INVEN_ALLOC') && open_M_EXT">
            <v-btn @click="movePage('P_STK_INVEN_ALLOC')" text block
              >&emsp;{{ getText("P_STK_INVEN_ALLOC") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_STK_IVEN_ALLOC_INDIVIDUAL') && open_M_EXT">
            <v-btn @click="movePage('P_STK_IVEN_ALLOC_INDIVIDUAL')" text block
              >&emsp;{{ getText("P_STK_IVEN_ALLOC_INDIVIDUAL") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_STK_IVEN_ALLOC_DEL') && open_M_EXT">
            <v-btn @click="movePage('P_STK_IVEN_ALLOC_DEL')" text block
              >&emsp;{{ getText("P_STK_IVEN_ALLOC_DEL") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 出荷-->
      <v-row v-if="getMenuRole('M_SHP')">
        <v-col>
          <div>
            <v-btn @click="open('M_SHP')" text block>{{ getText("M_SHP") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_SHP_ACTUAL_REPORT') && open_M_SHP">
            <v-btn @click="movePage('P_SHP_ACTUAL_REPORT')" text block
              >&emsp;{{ getText("P_SHP_ACTUAL_REPORT") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 在庫 -->
      <v-row v-if="getMenuRole('M_SRG')">
        <v-col>
          <div>
            <v-btn @click="open('M_SRG')" text block>{{ getText("M_SRG") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_SRG_INVEN_LIST') && open_M_SRG">
            <v-btn @click="movePage('P_SRG_INVEN_LIST')" text block
              >&emsp;{{ getText("P_SRG_INVEN_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SRG_STOCK_REPORT') && open_M_SRG">
            <v-btn @click="movePage('P_SRG_STOCK_REPORT')" text block
              >&emsp;{{ getText("P_SRG_STOCK_REPORT") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SRG_STOCK_TRANSITION') && open_M_SRG">
            <v-btn @click="movePage('P_SRG_STOCK_TRANSITION')" text block
              >&emsp;{{ getText("P_SRG_STOCK_TRANSITION") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_STC_STOCK_UPDATE_INSTRUCTION_LIST') && open_M_SRG">
            <v-btn @click="movePage('P_STC_STOCK_UPDATE_INSTRUCTION_LIST')" text block
              >&emsp;{{ getText("P_STC_STOCK_UPDATE_INSTRUCTION_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_WST_DEFECTIVE_DISCARDED_LIST') && open_M_SRG">
            <v-btn @click="movePage('P_WST_DEFECTIVE_DISCARDED_LIST')" text block
              >&emsp;{{ getText("P_WST_DEFECTIVE_DISCARDED_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_STC_RECEIVING_STOCK_COMPARE') && open_M_SRG">
            <v-btn @click="movePage('P_STC_RECEIVING_STOCK_COMPARE')" text block
              >&emsp;{{ getText("P_STC_RECEIVING_STOCK_COMPARE") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SRG_INVEN_LIST_BY_CLIENT') && open_M_SRG">
            <v-btn @click="movePage('P_SRG_INVEN_LIST_BY_CLIENT')" text block
              >&emsp;{{ getText("P_SRG_INVEN_LIST_BY_CLIENT") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- はい替え-->
      <v-row v-if="getMenuRole('M_MOV')">
        <v-col>
          <div>
            <v-btn @click="open('M_MOV')" text block>{{ getText("M_MOV") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_MOV_ORDER_ADD') && open_M_MOV">
            <v-btn @click="movePage('P_MOV_ORDER_ADD')" text block
              >&emsp;{{ getText("P_MOV_ORDER_ADD") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_MOV_ORDER_LIST') && open_M_MOV">
            <v-btn @click="movePage('P_MOV_ORDER_LIST')" text block
              >&emsp;{{ getText("P_MOV_ORDER_LIST") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 棚卸-->
      <v-row v-if="getMenuRole('M_CNT')">
        <v-col>
          <div>
            <v-btn @click="open('M_CNT')" text block>{{ getText("M_CNT") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_CNT_PLAN_ADD') && open_M_CNT">
            <v-btn @click="movePage('P_CNT_PLAN_ADD')" text block
              >&emsp;{{ getText("P_CNT_PLAN_ADD") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_CNT_PLAN_LIST') && open_M_CNT">
            <v-btn @click="movePage('P_CNT_PLAN_LIST')" text block
              >&emsp;{{ getText("P_CNT_PLAN_LIST") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 請求-->
      <v-row v-if="getMenuRole('M_BIL')">
        <v-col>
          <div>
            <v-btn @click="open('M_BIL')" text block>{{ getText("M_BIL") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_BIL_CONTRACT_LIST') && open_M_BIL">
            <v-btn @click="movePage('P_BIL_CONTRACT_LIST')" text block
              >&emsp;{{ getText("P_BIL_CONTRACT_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_BIL_CUT_OFF_MONTH_REGISTRATION') && open_M_BIL">
            <v-btn @click="movePage('P_BIL_CUT_OFF_MONTH_REGISTRATION')" text block
              >&emsp;{{ getText("P_BIL_CUT_OFF_MONTH_REGISTRATION") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_BIL_ACTUAL_LIST') && open_M_BIL">
            <v-btn @click="movePage('P_BIL_ACTUAL_LIST')" text block
              >&emsp;{{ getText("P_BIL_ACTUAL_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_BIL_STATEMENT') && open_M_BIL">
            <v-btn @click="movePage('P_BIL_STATEMENT')" text block
              >&emsp;{{ getText("P_BIL_STATEMENT") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 返品-->
      <v-row v-if="getMenuRole('M_RTN')">
        <v-col>
          <div>
            <v-btn @click="open('M_RTN')" text block>{{ getText("M_RTN") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_RTN_SLIP_ADD') && open_M_RTN">
            <v-btn @click="movePage('P_RTN_SLIP_ADD')" text block
              >&emsp;{{ getText("P_RTN_SLIP_ADD") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_RTN_SLIP_LIST') && open_M_RTN">
            <v-btn @click="movePage('P_RTN_SLIP_LIST')" text block
              >&emsp;{{ getText("P_RTN_SLIP_LIST") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 管理 -->
      <v-row v-if="getMenuRole('M_MNG')">
        <v-col>
          <div>
            <v-btn @click="open('M_MNG')" text block>{{ getText("M_MNG") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_MNG_COMP_SCHEDULE') && open_M_MNG">
            <v-btn @click="movePage('P_MNG_COMP_SCHEDULE')" text block
              >&emsp;{{ getText("P_MNG_COMP_SCHEDULE") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_MNG_ENT_EXT_HISTORY') && open_M_MNG">
            <v-btn @click="movePage('P_MNG_ENT_EXT_HISTORY')" text block
              >&emsp;{{ getText("P_MNG_ENT_EXT_HISTORY") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_MNG_OPE_HISTORY') && open_M_MNG">
            <v-btn @click="movePage('P_MNG_OPE_HISTORY')" text block
              >&emsp;{{ getText("P_MNG_OPE_HISTORY") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_MNG_LOCATION_BARCODE') && open_M_MNG">
            <v-btn @click="movePage('P_MNG_LOCATION_BARCODE')" text block
              >&emsp;{{ getText("P_MNG_LOCATION_BARCODE") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_MNG_TRACE_SEARCH') && open_M_MNG">
            <v-btn @click="movePage('P_MNG_TRACE_SEARCH')" text block
              >&emsp;{{ getText("P_MNG_TRACE_SEARCH") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 流通加工 -->
      <v-row v-if="getMenuRole('M_TMP')">
        <v-col>
          <div>
            <v-btn @click="open('M_TMP')" text block>{{ getText("M_TMP") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_TMP_901') && open_M_TMP">
            <v-btn @click="movePage('P_TMP_901')" text block
              >&emsp;{{ getText("P_TMP_901") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_TMP_902') && open_M_TMP">
            <v-btn @click="movePage('P_TMP_902')" text block
              >&emsp;{{ getText("P_TMP_902") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_TMP_903') && open_M_TMP">
            <v-btn @click="movePage('P_TMP_903')" text block
              >&emsp;{{ getText("P_TMP_903") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_TMP_910') && open_M_TMP">
            <v-btn @click="movePage('P_TMP_910')" text block
              >&emsp;{{ getText("P_TMP_910") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_TMP_912') && open_M_TMP">
            <v-btn @click="movePage('P_TMP_912')" text block
              >&emsp;{{ getText("P_TMP_912") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_TMP_913') && open_M_TMP">
            <v-btn @click="movePage('P_TMP_913')" text block
              >&emsp;{{ getText("P_TMP_913") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- 調達 -->
      <v-row v-if="getMenuRole('M_PRO')">
        <v-col>
          <div>
            <v-btn @click="open('M_PRO')" text block>{{ getText("M_PRO") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_SUP_DELIVERY_ORDER_PLAN_LIST') && open_M_PRO">
            <v-btn @click="movePage('P_SUP_DELIVERY_ORDER_PLAN_LIST')" text block
              >&emsp;{{ getText("P_SUP_DELIVERY_ORDER_PLAN_LIST") }}
            </v-btn>
          </div>
          <div v-if="open_M_PRO">
            <v-btn @click="movePage('P_SUP_USER_MAKER_LINK')" text block
              >&emsp;{{ getText("P_SUP_USER_MAKER_LINK") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SUP_DELIVERY_ORDER_SORTING_MASTER') && open_M_PRO">
            <v-btn @click="movePage('P_SUP_DELIVERY_ORDER_SORTING_MASTER')" text block
              >&emsp;{{ getText("P_SUP_DELIVERY_ORDER_SORTING_MASTER") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SUP_USER_ITEM_MASTER') && open_M_PRO">
            <v-btn @click="movePage('P_SUP_USER_ITEM_MASTER')" text block
              >&emsp;{{ getText("P_SUP_USER_ITEM_MASTER") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SUP_STOCK_TRANSITION') && open_M_PRO">
            <v-btn @click="movePage('P_SUP_STOCK_TRANSITION')" text block
              >&emsp;{{ getText("P_SUP_STOCK_TRANSITION") }}
            </v-btn>
          </div>
          <!-- <div v-if="getMenuRole('P_SUP_DELIVERY_ORDER_PLAN_SORTING_MASTER') && open_M_PRO">
            <v-btn @click="movePage('P_SUP_DELIVERY_ORDER_PLAN_SORTING_MASTER')" text block
              >&emsp;{{ getText("P_SUP_DELIVERY_ORDER_PLAN_SORTING_MASTER") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SUP_USER_PRODUCT_MASTER_LIST') && open_M_PRO">
            <v-btn @click="movePage('P_SUP_USER_PRODUCT_MASTER_LIST')" text block
              >&emsp;{{ getText("P_SUP_USER_PRODUCT_MASTER_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_SUP_USER_MAKER_LINK_MASTER_LIST') && open_M_PRO">
            <v-btn @click="movePage('P_SUP_USER_MAKER_LINK_MASTER_LIST')" text block
              >&emsp;{{ getText("P_SUP_USER_MAKER_LINK_MASTER_LIST") }}
            </v-btn>
          </div> -->
        </v-col>
      </v-row>
      <!-- マスタ -->
      <v-row v-if="getMenuRole('M_MST')">
        <v-col>
          <div>
            <v-btn @click="open('M_MST')" text block>{{ getText("M_MST") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_MST_WAREHOUSE_LIST') && open_M_MST">
            <v-btn @click="movePage('P_MST_WAREHOUSE_LIST')" text block
              >&emsp;{{ getText("P_MST_WAREHOUSE_LIST") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_MST_LOCATION_LIST') && open_M_MST">
            <v-btn @click="movePage('P_MST_LOCATION_LIST')" text block
              >&emsp;{{ getText("P_MST_LOCATION_LIST") }}
            </v-btn>
          </div>
          <div v-if="getMenuRole('P_MST_ITEM_MASTER_LIST') && open_M_MST">
            <v-btn @click="movePage('P_MST_ITEM_MASTER_LIST')" text block
              >&emsp;{{ getText("P_MST_ITEM_MASTER_LIST") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_MST_USER_MASTER_LIST') && open_M_MST">
            <v-btn @click="movePage('P_MST_USER_MASTER_LIST')" text block
              >&emsp;{{ getText("P_MST_USER_MASTER_LIST") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_MST_MASTER_FILE') && open_M_MST">
            <v-btn @click="movePage('P_MST_MASTER_FILE')" text block
              >&emsp;{{ getText("P_MST_MASTER_FILE") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('M_INI_BARCODE_MASTER') && open_M_MST">
            <v-btn @click="movePage('M_INI_BARCODE_MASTER')" text block
              >&emsp;{{ getText("M_INI_BARCODE_MASTER") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('M_INI_DP_MASTER_REGIST_LIST') && open_M_MST">
            <v-btn @click="movePage('M_INI_DP_MASTER_REGIST_LIST')" text block
              >&emsp;{{ getText("M_INI_DP_MASTER_REGIST_LIST") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 全社進捗 -->
      <v-row v-if="getMenuRole('M_TRS')">
        <v-col>
          <div>
            <v-btn @click="open('M_TRS')" text block>{{ getText("M_TRS") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_WIDE_TRANSITION') && open_M_TRS">
            <v-btn @click="movePage('P_WIDE_TRANSITION')" text block
              >&emsp;{{ getText("P_WIDE_TRANSITION") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 取引先営業所別 -->
      <v-row v-if="getMenuRole('M_CLT')">
        <v-col>
          <div>
            <v-btn @click="open('M_CLT')" text block>{{ getText("M_CLT") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_CLT_INVEN_LIST_BY_CLIENT') && open_M_CLT">
            <v-btn @click="movePage('P_CLT_INVEN_LIST_BY_CLIENT')" text block
              >&emsp;{{ getText("P_CLT_INVEN_LIST_BY_CLIENT") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 受注 -->
      <v-row v-if="getMenuRole('M_TOD')">
        <v-col>
          <div>
            <v-btn @click="open('M_TOD')" text block>{{ getText("M_TOD") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_TOD_ADD') && open_M_TOD">
            <v-btn @click="movePage('P_TOD_ADD')" text block
              >&emsp;{{ getText("P_TOD_ADD") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_TOD_ORDER_LIST') && open_M_TOD">
            <v-btn @click="movePage('P_TOD_ORDER_LIST')" text block
              >&emsp;{{ getText("P_TOD_ORDER_LIST") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_TOD_LIST') && open_M_TOD">
            <v-btn @click="movePage('P_TOD_LIST')" text block
              >&emsp;{{ getText("P_TOD_LIST") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 配車 -->
      <v-row v-if="getMenuRole('M_DVP')">
        <v-col>
          <div>
            <v-btn @click="open('M_DVP')" text block>{{ getText("M_DVP") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_DVP_LIST') && open_M_DVP">
            <v-btn @click="movePage('P_DVP_LIST')" text block
              >&emsp;{{ getText("P_DVP_LIST") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_DVP_ADD') && open_M_DVP">
            <v-btn @click="movePage('P_DVP_ADD')" text block
              >&emsp;{{ getText("P_DVP_ADD") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_DVP_PLAN_DATA') && open_M_DVP">
            <v-btn @click="movePage('P_DVP_PLAN_DATA')" text block
              >&emsp;{{ getText("P_DVP_PLAN_DATA") }}</v-btn
            >
          </div>
          <div v-if="getMenuRole('P_DVP_REGISTER_VEHICLE_LIST') && open_M_DVP">
            <v-btn @click="movePage('P_DVP_REGISTER_VEHICLE_LIST')" text block
              >&emsp;{{ getText("P_DVP_REGISTER_VEHICLE_LIST") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 輸送 -->
      <v-row v-if="getMenuRole('M_TRN')">
        <v-col>
          <div>
            <v-btn @click="open('M_TRN')" text block>{{ getText("M_TRN") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_TRN_INQUIRY') && open_M_TRN">
            <v-btn @click="movePage('P_TRN_INQUIRY')" text block
              >&emsp;{{ getText("P_TRN_INQUIRY") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 運行 -->
      <v-row v-if="getMenuRole('M_OPM')">
        <v-col>
          <div>
            <v-btn @click="open('M_OPM')" text block>{{ getText("M_OPM") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_OPM_001') && open_M_OPM">
            <v-btn @click="movePage('P_OPM_001')" text block
              >&emsp;{{ getText("P_OPM_001") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 帳票 -->
      <v-row v-if="getMenuRole('M_TMF')">
        <v-col>
          <div>
            <v-btn @click="open('M_TMF')" text block>{{ getText("M_TMF") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_TMF_OUTPUT') && open_M_TMF">
            <v-btn @click="movePage('P_TMF_OUTPUT')" text block
              >&emsp;{{ getText("P_TMF_OUTPUT") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- 請求 -->
      <v-row v-if="getMenuRole('M_CLA')">
        <v-col>
          <div>
            <v-btn @click="open('M_CLA')" text block>{{ getText("M_CLA") }}</v-btn>
          </div>
          <div v-if="getMenuRole('P_CLA_COST_LIST') && open_M_CLA">
            <v-btn @click="movePage('P_CLA_COST_LIST')" text block
              >&emsp;{{ getText("P_CLA_COST_LIST") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <!-- パターン -->
      <v-row v-if="getMenuRole('menu_pattern')">
        <v-col>
          <div>
            <v-btn @click="open('pattern')" text block>パターン</v-btn>
          </div>
          <div v-if="open_pattern">
            <v-btn @click="movePage('menu_import')" text block>&nbsp;&nbsp;取込</v-btn>
          </div>
          <div v-if="open_pattern">
            <v-btn @click="movePage('menu_listAdd3')" text block>&nbsp;&nbsp;登録1</v-btn>
          </div>
          <div v-if="open_pattern">
            <v-btn @click="movePage('menu_register2')" text block>&nbsp;&nbsp;登録2</v-btn>
          </div>
          <div v-if="open_pattern">
            <v-btn @click="movePage('menu_listPopup')" text block
              >&nbsp;&nbsp;検索（P一覧・P検索）</v-btn
            >
          </div>
          <div v-if="open_pattern">
            <v-btn @click="movePage('menu_listAdd1')" text block>&nbsp;&nbsp;P登録</v-btn>
          </div>
          <div v-if="open_pattern">
            <v-btn @click="movePage('menu_list1')" text block>&nbsp;&nbsp;リスト1</v-btn>
          </div>
          <div v-if="open_pattern">
            <v-btn @click="movePage('menu_listAdd2')" text block>&nbsp;&nbsp;リスト登録2</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
//import Loading from "@/components/loading";
//import SimpleDialog from "@/components/SimpleDialog";

import { appConfig } from "../assets/scripts/js/AppConfig";
import { i18n } from "../lang/lang.js";
//import { dateTimeHelper } from "../assets/scripts/js/DateTimeHelper";
//import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
export default {
  components: {},
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    menu: {},
    // メニュー権限
    menuRole: {},
    // 多言語テキスト
    menuText: {},
    // 画面権限
    screenRole: {},
    /**ここからメニュー */
    // 入荷準備
    open_M_RCV_RDY: false,
    // 入荷
    open_M_RCV: false,
    // 入庫
    open_M_ENT: false,
    // 出荷準備
    open_M_SHP_RDY: false,
    // 流通加工
    open_M_TMP: false,
    // 出庫
    open_M_EXT: false,
    // 出荷
    open_M_SHP: false,
    // 在庫
    open_M_SRG: false,
    // はい替え
    open_M_MOV: false,
    // 棚卸
    open_M_CNT: false,
    // 返品
    open_M_RTN: false,
    // 請求
    open_M_BIL: false,
    // 管理
    open_M_MNG: false,
    // マスタ
    open_M_MST: false,
    // 全社進捗
    open_M_TRS: false,
    // 取引先営業所別
    open_M_CLT: false,
    // 受注
    open_M_TOD: false,
    open_pattern: false,
    // 配車
    open_M_DVP: false,
    // 輸送
    open_M_TRN: false,
    // 運行
    open_M_OPM: false,
    //調達
    open_M_PRO: false,
    //帳票
    open_M_TMF: false,
    // 請求
    open_M_CLA: false,
  }),
  methods: {
    open(menuNm) {
      for (var value in this.menuRole) {
        if (menuNm == value) {
          eval("this.open_" + value + " = !this.open_" + value + ";");
        } else {
          eval("this.open_" + value + "= false");
        }
      }
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      for (var value in sessionStorage) {
        if (value.substring(0, 2) == "P-") {
          var key = this.getMenuIdValueByKey(value);
          this.screenRole[key] = sessionStorage.getItem(value);
          this.menuText[key] = i18n.tc("menu." + value);
          if (value.substring(0, 7) == "P-MENU-") {
            this.menuRole[key] = false;
          }
        }
      }
      this.screenRole["menu_pattern"] = true;
      this.menuRole["pattern"] = false;
    },
    async movePage(pageNm) {
      if (pageNm.substring(0, 5) == "menu_") {
        this.$router.push(pageNm.substring(5, pageNm.length));
      } else {
        if (this.$route.name !== appConfig.MENU_ID[pageNm]) {
          this.$router.push({ name: appConfig.MENU_ID[pageNm] });
        }
      }
    },
    getMenuIdValueByKey(value) {
      return Object.keys(appConfig.MENU_ID).find((key) => appConfig.MENU_ID[key] === value);
    },

    getMenuRole(val) {
      return this.screenRole[val] == "true" ? true : false;
    },
    getRole(val) {
      return this.menuRole[val] == "true" ? true : false;
    },
    getText(val) {
      return this.menuText[val];
    },
  },
  computed: {},
  watch: {
    menuRole: {
      handler(newValue) {
        // console.debug("watch", newValue, oldValue);
        this.getRole(newValue);
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
#sub-bar {
  color: black;
  background: $color-obj-base;
  padding: 0;
  height: 3rem;
  text-align: left;

  #lbl-screen-name {
    font-size: x-large;
    font-weight: bold;
    display: inline-block;
  }

  .right {
    text-align: right;
  }
}

.v-btn.v-size--default {
  font-size: 18px !important;
  margin-left: 8px;
  // width: 33%;
}

.v-btn {
  // width: 33%;
  // text-align: left !important;
  .v-btn--has-bg {
    background: $color-obj-base;

    &.api-btn {
      width: 100%;
    }
  }
}

.v-btn ::v-deep {
  justify-content: flex-start;
}

.btn-home {
  margin-top: 16px;
}

#display-type {
  width: 25rem;
  height: 5.5rem;

  .v-card__title {
    font-size: 1rem;
    padding: 0 0.2rem;
  }

  .toggle {
    padding: 0.3rem 2rem;

    .btn {
      width: 5rem;
      height: 2.5rem;
    }
  }
}

.v-navigation-drawer
  v-navigation-drawer--absolute
  v-navigation-drawer--is-mobile
  v-navigation-drawer--open
  v-navigation-drawer--temporary
  theme--light {
  width: 60% !important;
}
</style>
